import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SitesWrappingContainerComponent } from '@app-shared/components/sites-wrapping/sites-wrapping.container.component';
import { SharedModule } from '@app-shared/shared.module';
import { CustomPreloadingStrategy } from './custom.preloading-strategy';
import { SiteName } from '@app-shared/models/routingdata';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { EntryContainerComponent } from 'src/entry/entry.container.component';

const routes: Routes = [
  {
    path: '',component: EntryContainerComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'entry', component:MsalRedirectComponent,
  },
  {
    path: 'origination', component: SitesWrappingContainerComponent,
    loadChildren: () => import('../origination/origination.module').then(m => m.OriginationModule),
    data: {
      preload: true,
      siteName: SiteName.Origination
    }
  },
  {
    path: 'empf', component: SitesWrappingContainerComponent,
    loadChildren: () => import('../empf/empf.module').then(m => m.EmpfModule),
    data: {
      preload: true,
      siteName: SiteName.Empf
    }
  },
  {
    path: 'error',
    loadChildren: () => import('../error/error.module').then(m => m.ErrorModule)
  },
  {
    path: 'eMPF', redirectTo: ''
  },
  {
    path: '**', redirectTo: 'error'
  }    
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: false, enableTracing: false, preloadingStrategy: CustomPreloadingStrategy }),
    SharedModule,
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
