import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoginService, AppConfigService } from '@app-shared/services';
import { IAccountLogin } from './components/models/account-login.model';
import { fhlbConstants } from '@app-shared/constants/fhlb-constants';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { take, map, catchError, filter } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { getLoginFlowFromStore, getRolesFromStore, getSelectedProfileScopeId } from 'src/store/selectors';
import { isNullOrUndefined } from 'is-what';
import { Store } from '@ngrx/store';
import { AppState } from 'src/store/reducers';
import { HlbBankLoginAction, LogInSuccessAction, LogOutAction, UserProfileSelectedAction } from 'src/store/actions/auth.actions';
import { ILookup } from './models/lookup.model';
import { TreasuryService } from '@empf-shared/services/treasury.service';
import { OriginationRoutingHelper } from '@origination-shared/navigation/origination.navigation';
import { EmpfRoutingHelper } from '@empf-shared/navigation/empf.navigation';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { DecodeAzureB2CIDToken } from 'src/store/reducers/models/DecodeAzureB2CIDToken';
import { UserProfile, UserProfilePicker } from 'src/store/reducers/models/UserProfile';
import { AuthData } from 'src/store/reducers/models/AuthData';
import { Router } from '@angular/router';
import { LoginFlow } from '@app-shared/constants/login-flow';

@Component({
  selector: 'entry',
  templateUrl: './entry.container.component.html',
  styleUrls: ['./entry.sass'],
  encapsulation: ViewEncapsulation.None
})

export class EntryContainerComponent implements OnInit {
  administrationHomePageRoute = OriginationRoutingHelper.Home();
  empfHomePageRoute = EmpfRoutingHelper.HomeLandingPath();
  eMpfFhlBankUserMpf: string = AppConfigService.Settings.Empf.EMPF_FHLBANKUSER_MPF;
 
  @BlockUI() blockUI: NgBlockUI;

  profileScopeIDfromStore: string;
  profileRolesFromStore: string[];
  userProfileList: UserProfilePicker[];
  profile: UserProfile;
  loginFlow: LoginFlow = LoginFlow.NO_FLOW;
  LoginFlowType = LoginFlow;
  entryLogging: boolean = AppConfigService.Settings.App.Logging.B2CEntryLogging;

  accountLoginModel: IAccountLogin;
  hlbList: ILookup[];
  

  constructor(private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private treasuryService: TreasuryService, 
    private router: Router, 
    private loginService: LoginService,
    private store: Store<AppState>) 
  {
    this.accountLoginModel = {} as IAccountLogin;
    this.profile = {} as UserProfile;
  }

  ngOnInit(): void {
    if (this.entryLogging) console.log("entry component ng onint");
    this.blockUI.start();

    this.store.select(getLoginFlowFromStore).pipe(map(x => 
      { 
        if (this.entryLogging) console.log("entry component LoginFlow:", x);
        // REFRESH_TOKENS_SUCCESS action will change LoginFlow state, flipping us over to FHLBANK_USER_LOGIN_FLOW.
        // This will hide the User Profile picker and instead display the FHLBankUser HLB picker, all on this same component.
        if (x == LoginFlow.FHLBANK_USER_LOGIN_FLOW)
        {
          this.loginFlow = x;
          this.accountLoginModel.isFHLBankUser = true;
          this.populateHLB();
        }
      })).subscribe();
    this.store.select(getSelectedProfileScopeId).pipe(take(1), map(x => { this.profileScopeIDfromStore = x })).subscribe();
    this.store.select(getRolesFromStore).pipe(take(1), map(x => { this.profileRolesFromStore = x })).subscribe();

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        if (this.entryLogging) console.log("Inside entry component msalsubject", result);
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        if (this.entryLogging) console.log("Inside msal inprogress");
        
        var encodedIdToken = this.authService.instance.getActiveAccount()?.idToken;
        if (this.entryLogging) console.log("Azure B2C ID token: ", encodedIdToken); 
        var b2cTokenClaims = (this.authService.instance.getActiveAccount()?.idTokenClaims as unknown) as DecodeAzureB2CIDToken;
        if (this.entryLogging) console.log("Azure B2C ID token claims: ", b2cTokenClaims);    

        let b2cTokenExp = new Date(b2cTokenClaims.exp * 1000);
        let dateNow = new Date();
        if (this.entryLogging) console.log("Curent time: ", dateNow);
        if (this.entryLogging) console.log("B2C Token expiration time: ", b2cTokenExp);
        // Ensure the current B2C token from MSAL cache is still valid/not expired. Log out the user from Azure if expired.
        if (dateNow > b2cTokenExp)
        {
          if (this.entryLogging) console.log("Expired B2C Token. Force logging out.")
          this.store.dispatch(new LogOutAction({ acknowledge: false }));
        }
        // If user navigates to/reloads base empf.com and has already selected a Profile, then simply redirect them to their proper home page
        else if (!isNullOrUndefined(this.profileScopeIDfromStore))
        {
          if (this.entryLogging) console.log("Currently selected ProfileScopeID:", this.profileScopeIDfromStore);
          let homeRoute = this.determineHomeRoute(this.profileScopeIDfromStore, this.profileRolesFromStore);
          this.blockUI.stop();
          this.router.navigate([homeRoute]);
        }
        // User has not selected a profile and B2C token is still valid: Persist initial state of B2C Token data to Store and setup User Profile picker logic
        else
        {
          if (this.entryLogging) console.log("User authenticated with Azure B2C successfully! And B2C Token is still valid.");
          this.store.dispatch(new LogInSuccessAction({ decodedAzureB2CIDToken: b2cTokenClaims, encodedAzureB2CIDtoken: encodedIdToken }));

          const data = (b2cTokenClaims.authData as unknown) as string;
          this.blockUI.stop();
          if (data)
          {
            const authData = JSON.parse(data) as AuthData;
            if (this.entryLogging) console.log(authData.UserProfiles);

            const fhlbcUserProfileExists = authData.UserProfiles.find(profile => profile.ProfileScopeID === "FHLBC-User")
            if(fhlbcUserProfileExists)
            {
              if(!fhlbcUserProfileExists.Roles || fhlbcUserProfileExists.Roles.length === 0)
              {
                authData.UserProfiles = authData.UserProfiles.filter(profile => profile.ProfileScopeID !== "FHLBC-User")
              }
            }

            // If user only has 1 Profile to choose from, auto-login with their only profile option
            if (authData.UserProfiles && authData.UserProfiles.length == 1)
            {
              this.loginProfileUser(authData.UserProfiles[0]);
            }
            // Else, display User Profile dropdown and force the user to make a selection
            else if (authData.UserProfiles && authData.UserProfiles.length > 1)
            {
              this.userProfileList = authData.UserProfiles as UserProfilePicker[];
              this.loginFlow = LoginFlow.USER_PROFILE_LOGIN_FLOW;
            }
            else 
            {
              this.router.navigate([fhlbConstants.unauthorizedRoute]);
            }
          }
          else
          {
            this.router.navigate([fhlbConstants.unauthorizedRoute]);
          }
        }
      });
  }

  loginProfileUser(loginModel: UserProfile) {
    if (!this.blockUI.isActive)
      this.blockUI.start();

    if (this.entryLogging) console.log("Selected profile data in entry container:", loginModel);
    this.profile = loginModel;

    // If user selected Profile with FHLBank role, switch over to HLB picker after retriving access token
    let secondaryLoginFlow = LoginFlow.USER_PROFILE_LOGIN_FLOW;
    let upperCaseGroups = loginModel.Roles.map(function(roleString) { return roleString.toUpperCase(); });
    if (upperCaseGroups.includes(this.eMpfFhlBankUserMpf.toUpperCase()))
    {
      secondaryLoginFlow = LoginFlow.FHLBANK_USER_LOGIN_FLOW;
    }

    let navigateToHomeRoute = this.determineHomeRoute(loginModel.ProfileScopeID, loginModel.Roles);
    this.store.dispatch(new UserProfileSelectedAction(
      {
        selectedProfile: loginModel.ProfileScopeID,
        userProfile: loginModel,
        navigateToHome: navigateToHomeRoute,
        loginFlow: secondaryLoginFlow
      }));

  }

  determineHomeRoute(profileScopeID: string, profileRoles: string[]): string {
    // Determine where to route the user after having selected their User Profile and successfully retrieving ID/Access Tokens
    let navigateToHomeRoute = fhlbConstants.unauthorizedRoute;
    if (profileScopeID.toUpperCase().includes('NTUSERNAME'))
    {
      navigateToHomeRoute = this.empfHomePageRoute;
    }
    else if (profileScopeID.toUpperCase().includes('FHLBC-USER'))
    {
      profileRoles.forEach(role =>
        {
          if (AppConfigService.Settings.Empf.Fhlbc_UserRoles.find(x => x.toUpperCase() == role.toUpperCase()) != undefined) {
            navigateToHomeRoute = this.administrationHomePageRoute;
          }
        }
      );
    }
    return navigateToHomeRoute;
  }

  loginFhlBankUser(loginModel: IAccountLogin) {
    if (!this.blockUI.isActive)
      this.blockUI.start();

    this.treasuryService.getEncryptedHLB({ fhlBankId:loginModel.fhlBankId, fhlBankName:loginModel.fhlBankName }).pipe(take(1), map((response) => {
        if (response) 
        {
          this.store.dispatch(new HlbBankLoginAction({ mpfBankKey: response.mpfBankKey, mpfBankName: response.mpfBankName }));
          let homeRoute = this.determineHomeRoute(this.profile.ProfileScopeID, this.profile.Roles);
          this.router.navigate([homeRoute]);
        }
        else
        {
          if (this.entryLogging) console.log("Bad response from GetEncryptedHLB API:", response);
          this.router.navigate([fhlbConstants.unauthorizedRoute]);
        }
      },
        catchError((error) => {
          if (this.entryLogging) console.log("Unable to get encrypted HLB details:", error);
          this.router.navigate([fhlbConstants.unauthorizedRoute]);
          return EMPTY;
        })
      )).subscribe(); 
  }

  private populateHLB() {
    if (!this.blockUI.isActive)
      this.blockUI.start();

    this.loginService.getHLBForFHLBank().pipe(take(1), map((response) => {
      if (response.success === true) {
        this.hlbList = response.lookupList;
      }
      if (!response.success) {
        const errors = [];
        errors.push(response.errorMessage);
      }
      this.blockUI.stop();
    },
      catchError((error) => {
        this.blockUI.stop();
        return EMPTY;
      })
    )).subscribe();
  }

}
