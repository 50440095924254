import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, InjectionToken } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LocationStrategy, APP_BASE_HREF, CurrencyPipe, DatePipe, DecimalPipe, PercentPipe, PathLocationStrategy, CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NgbDateParserFormatter, NgbDateAdapter, NgbModule, NgbDatepickerConfig, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app-shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { CustomDateParserFormatter } from '@app-shared/helpers/date/date-formatter';
import { CustomDateAdapter } from '@app-shared/helpers/date/date-adapter';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppConfigService } from '@app-shared/services';
import { initializeApp } from '@app-shared/helpers/initialize-helper';
import { StoreModule, MetaReducer, ActionReducer } from '@ngrx/store';
import { appReducers } from '../store/reducers';
import { AuthGuard } from '../guards/auth.guard';
import { localStorageSync } from 'ngrx-store-localstorage';
import { CookieService } from 'ngx-cookie-service';
import { BlockTemplateComponent } from '@app-shared/components/block-ui-template.component';
import { BlockUIModule } from 'ng-block-ui';
import { WarningToastComponent } from 'src/shared/components/toastr-component/warning/warning-toastr.component';
import { HttpUtilHelper } from '@app-shared/helpers/http-util-helper';
import { UtilHelper } from '@app-shared/helpers/util-helper';
import { JQ_TOKEN } from '@app-shared/services';
import { SuccessToastComponent } from '@app-shared/components/toastr-component/success/success-toastr.component';
import { ToastComponent } from '@app-shared/components/toastr-component/error/toastr.component';
import { OriginationModule } from 'src/origination/origination.module';
import { AuthEffects } from 'src/store/effects/auth.effects';
import { EffectsModule } from '@ngrx/effects';
import { CustomPreloadingStrategy } from './custom.preloading-strategy';
import { CustomDatePickerConfig } from '@app-shared/services/configuration/date-picker.config';
import { EmpfModule } from 'src/empf/empf.module';
import { AuthInterceptor } from '@app-shared/interceptors/auth-interceptor';
import { SetPageTitlesComponent } from 'src/app/set-page-titles/set-page-titles.component';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE } from '@azure/msal-angular';
import { EntryContainerComponent } from 'src/entry/entry.container.component';
import { EntryLandingComponent } from 'src/entry/components/entry-landing/entry.component';
import { RouterModule } from '@angular/router';
import { UserProfileComponent } from 'src/entry/components/user-profile/user-profile.component';
import { provideBootstrapEffects } from '@app-shared/utils/ngrx-boostrap-effects';

export const APP_BOOTSTRAP_EFFECTS = new InjectionToken('App Bootstrap Effects');

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  const msalLogLevelAppConfig = AppConfigService.Settings.App.Logging.MSALLoggerLevel;
  let msalLogLevelString: keyof typeof LogLevel = msalLogLevelAppConfig as keyof typeof LogLevel;

  return new PublicClientApplication({
    auth: {
      clientId: AppConfigService.Settings.msalConfig.auth.clientId,
      authority: AppConfigService.Settings.b2cPolicies.authorities.signUpSignIn.authority,
      redirectUri: AppConfigService.Settings.msalConfig.auth.redirectUri,
      postLogoutRedirectUri: AppConfigService.Settings.msalConfig.auth.postLogoutRedirectUri,
      knownAuthorities: [AppConfigService.Settings.b2cPolicies.authorityDomain]
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel[msalLogLevelString],
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      extraQueryParameters: {
        brand: 'mpf',
        ciamauthz: 'mpf'
      }
    },
    loginFailedRoute: '/error/unauthorized'
  };
}



let jQuery = window['$'];

//Saving/getting the data from localstorage so that ngrx store will always have the data even after mvc redirection.
export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: ['auth'],
    rehydrate: true
  })(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  declarations: [
    AppComponent,
    ToastComponent,
    SuccessToastComponent,
    WarningToastComponent,
    BlockTemplateComponent,
    SetPageTitlesComponent,
    EntryContainerComponent,
    EntryLandingComponent,
    UserProfileComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    NgbModalModule,
    NgbModule,
    BlockUIModule.forRoot({
      template: BlockTemplateComponent
    }),
   // RouterModule.forChild(routes),
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    SharedModule,
    NoopAnimationsModule,
    HttpClientModule,
    NgbModule,
    EmpfModule,
    BlockUIModule.forRoot({
      template: BlockTemplateComponent
    }),
    ToastrModule.forRoot({
      maxOpened: 0,
      autoDismiss: true,
      newestOnTop: false
    }),
    StoreModule.forRoot(appReducers, { metaReducers }),
    EffectsModule.forRoot([]),  
    OriginationModule,
    EmpfModule,
    MsalModule,
    BrowserModule
    
  ],
  bootstrap: [AppComponent,MsalRedirectComponent],
  providers: [
    CustomPreloadingStrategy,
    { provide: JQ_TOKEN, useValue: jQuery },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: NgbDateAdapter, useClass: CustomDateAdapter },
    { provide: NgbDatepickerConfig, useClass: CustomDatePickerConfig },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppConfigService, CookieService], multi: true },
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory},
    { provide: MSAL_GUARD_CONFIG,useFactory: MSALGuardConfigFactory},
    AppConfigService,
    AuthGuard,
    HttpUtilHelper,
    UtilHelper,
    CurrencyPipe, 
    DatePipe, 
    DecimalPipe, 
    PercentPipe,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    provideBootstrapEffects([AuthEffects],APP_BOOTSTRAP_EFFECTS),
  ]
})

export class AppModule { }
