import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { map, take } from 'rxjs/operators'
import { MessagesService } from 'src/empf/shared/services/header/messages-service';
import { Router } from '@angular/router'
import { IMessagesList } from 'src/empf/shared/services/header/messages/models/IMessagesList';
import { AppState } from 'src/store/reducers';
import { Store } from '@ngrx/store';
import { SitesWrappingContainerComponent } from '@app-shared/components/sites-wrapping/sites-wrapping.container.component';
import { AppConfigService, UserService } from '@app-shared/services';
import { getIsBehaviorFoundFromStore, getIsFhlbcUserFromStore, getUserIdFromStore, getUserOrgKeyFromStore, getUserProfileCountFromStore } from 'src/store/selectors';
import { LogOutAction, UserProfileClearedAction } from 'src/store/actions/auth.actions';
import { BroadCastService } from '@app-shared/services/broadcast.service';
import { EmpfRoutingHelper } from '@empf-shared/navigation/empf.navigation';

@Component({
  selector: 'sites-header-container',
  templateUrl: './sites-header.component.html',
  styleUrls: ['./sites-header.component.sass']
})
export class SitesHeaderComponent implements OnInit {
  
  @Input() isRoles: SitesWrappingContainerComponent;
  userId: string;
  lastLoginDate: string;
  lastLoginTime: string;
  userOrgKey: string;
  isEmpfSecurityAdmin: boolean;
  unreadMessages: number;
  isInternalUser: boolean;
  messages: IMessagesList[];
  isServiceNowButtonVariable: boolean;
  userProfileCount: number = 3;

  constructor(
    private store: Store<AppState>, private userService: UserService, private messagesService: MessagesService, private router: Router, private location: Location,
    private broadCastService: BroadCastService) { }

  ngOnInit() {  
          
    
    this.store.select(getUserIdFromStore).pipe(take(1),
      map(x => {
        this.userId = x
      })
    ).subscribe();

    this.store.select(getIsBehaviorFoundFromStore(['eMPF Security Administrator'])).pipe(take(1),
      map(x => {
        this.isEmpfSecurityAdmin = x
      })
    ).subscribe();

    this.store.select(getUserOrgKeyFromStore).pipe(take(1),
      map(x => {
        this.userOrgKey = x
      })
    ).subscribe();

    this.store.select(getIsFhlbcUserFromStore).pipe(take(1),
      map(x => {
        this.isInternalUser = x
      })
    ).subscribe();

    this.store.select(getUserProfileCountFromStore).pipe(take(1), map(x => { this.userProfileCount = x })).subscribe();

    this.userService.getLastLogin().pipe(take(1), map((resp) => {
      this.lastLoginDate = resp.lastloginDate;
      this.lastLoginTime = resp.lastloginTime;
    })).subscribe();

    this.loadMessages();

    this.broadCastService.on('loadTopHeaderMessages', callback => {
      //This method was executing while downloading pricing file may be due to some signalr event emits, so load messages was executing
      //unneccessarily number of times.
      if(callback == 'loadTopHeaderMessages') {
        this.loadMessages();
      } 
    });
    this.isServiceNowButtonVariable = AppConfigService.Settings.App.ServiceNowButton;
  }

  loadMessages() {
    this.messagesService.getMessagesList().pipe(take(1), map((resp) => {
      resp.messages.forEach(msg => {
        msg.startDateTime += ' CT';
      });
      this.messages = resp.messages;
      this.unreadMessages = this.messages.filter(msg => msg.isRead === false && msg.isDismissed === false).length;
    })).subscribe();
  }

  navToMessage(messageId: number) {
    this.router.navigate([EmpfRoutingHelper.HeaderMessagesLandingPath()], { queryParams: { messageId: messageId } });
  }
  navToManageUsers(userOrgKey: string) {
    this.router.navigate([EmpfRoutingHelper.HeaderManageUsersLandingPath(userOrgKey)]);
  }
  navToProfile(){
    this.router.navigate([EmpfRoutingHelper.ProfileLandingPath()]);
  }
  navToMessages(){
    this.router.navigate([EmpfRoutingHelper.HeaderMessagesLandingPath()]);
  }
  navToContact(){
    this.router.navigate([EmpfRoutingHelper.HeaderContactUsLandingPath()]);
  }
  navToUserProfilePicker() {
    this.store.dispatch(new UserProfileClearedAction());
  }

  logout() {
    this.store.dispatch(new LogOutAction({acknowledge: false}));
  }

  servicenow() {
    if(this.isRoles)  {
      window.open(AppConfigService.Settings.Security.ServiceNowExternalUserUrl, '_blank');
    }
    else{
      window.open(AppConfigService.Settings.Security.ServiceNowInternalUserUrl, '_blank');
    }
  } 
  
}
