import { fhlbConstants } from '@app-shared/constants/fhlb-constants';
import { AppConfigService } from '@app-shared/services/configuration/app-config.service';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isNullOrUndefined } from 'is-what';
import * as auth from '../reducers/auth.reducers';
import { AuthData } from '../reducers/models/AuthData';

const selectAuthStore = createFeatureSelector<auth.State>('auth');
export const selectAuthState = createSelector(selectAuthStore, (state) => state);
export const getRefreshStatusFromStore = createSelector(selectAuthStore, (state) => state.isRefreshOccurring);
export const getDecodedIdTokenFromStore = createSelector(selectAuthStore, (state) => state.decoded.idToken);
export const getAccessTokenFromStore = createSelector(selectAuthStore, (state) => state.encoded.azureB2cAccessToken);
export const getUserGivenNameFromStore = createSelector(selectAuthStore, (state) => state.decoded.idToken.given_name);
export const getSiteForUserFromStore = createSelector(selectAuthStore, (state) => state.site);
export const getLoginFlowFromStore = createSelector(selectAuthStore, (state) => state.loginFlow);
export const getExpiryDateFromStore = createSelector(selectAuthStore, (state) => state.azureB2cTokenExpireDateTime);
export const getRolesFromStore = createSelector(selectAuthStore, (state) => (state.decoded.idToken) ? state.decoded.idToken['custom:UserGroup'] : []);
export const getHLBFromStore = createSelector(selectAuthStore, (state) => state.hlbBankInfo);
export const getSelectedProfileScopeId = createSelector(selectAuthStore, (state) => state.selectedProfileScopeID);
export const getEmpfAppShowRefreshModalDateTimeFromStore = createSelector(selectAuthStore, (state) => state.empfApplicationShowRefreshModalDateTime);
export const getEmpfAppExpTimeStamp = createSelector(selectAuthStore, (state) => state.empfApplicationExpireDateTime);


export const getLogInStatusFromStore = createSelector(selectAuthStore, (state) => {
    const selectedProfileScopeID = state.selectedProfileScopeID;
    if (!isNullOrUndefined(selectedProfileScopeID)) {
        return true;
    }
    else return false;
});


export const getIsFhlbcUserFromStore = createSelector(selectAuthStore, (state) => {
    let isInternalUser: boolean = false;
    const idToken = state.decoded.idToken;
    if (idToken) {
        if (isNullOrUndefined(idToken)) {
            isInternalUser = false;
        } else {
            if (!isNullOrUndefined(idToken['custom:UserGroup']) && idToken['custom:UserGroup'].length > 0)
                idToken['custom:UserGroup'].forEach(role => {
                    if (AppConfigService.Settings.Empf.Fhlbc_UserRoles.find(x => x.toUpperCase() == role.toUpperCase()) != undefined) {
                        isInternalUser = true;
                    }
                })
        }
    } else {
        isInternalUser = false;
    }
    return isInternalUser;
});


export const getIsFhlbBankUserFromStore = createSelector(selectAuthStore, (state) => {
    let isBankUser: boolean = false;
    const idToken = state.decoded.idToken;
    if (idToken) {
        if (isNullOrUndefined(idToken)) {
            isBankUser = false;
        } else {
            if (!isNullOrUndefined(idToken['custom:UserGroup']) && idToken['custom:UserGroup'].length > 0) {
                if (idToken['custom:UserGroup'].find(role => role.toUpperCase() == fhlbConstants.EmpfFhlbankuserMpf.toUpperCase()))
                    isBankUser = true;
            }
        }
    } else {
        isBankUser = false;
    }
    return isBankUser;
});

export const getUserIdFromStore = createSelector(selectAuthStore, (state) => {
    const idToken = state.decoded.idToken;
    let userId: string = null;

    if (!isNullOrUndefined(idToken) && !isNullOrUndefined(idToken.identities) && idToken.identities.length > 0)
        userId = idToken.identities[0].userId;

    return userId;
});

export const getUserOrgKeyFromStore = createSelector(selectAuthStore, (state) => {
    const idToken = state.decoded.idToken;
    let userOrgKey: string = null;

    if (!isNullOrUndefined(idToken))
        userOrgKey = idToken['custom:OrgKey'];

    return userOrgKey;
});

export const getIsRoleFoundFromStore = (roles: string[]) =>
    createSelector(selectAuthStore, (state) => {
        const idToken = state.decoded.idToken;
        let roleFound: boolean = false;
        let rolesToSearchFor_UPPERCASE = roles.map((function (roleString) { return roleString.toUpperCase(); }));
        let userRoles_UPPERCASE = idToken['custom:UserGroup'].map((function (roleString) { return roleString.toUpperCase(); }));

        if (!isNullOrUndefined(idToken))
            rolesToSearchFor_UPPERCASE.forEach(role => {
                if (userRoles_UPPERCASE.indexOf(role) != -1) {
                    roleFound = true;
                }
            });

        return roleFound;
    })

export const getIsBehaviorFoundFromStore = (roles: string[]) =>
    createSelector(selectAuthStore, (state) => {
        let roleFound: boolean = false;
        const idToken = state.decoded.idToken

        if (!isNullOrUndefined(idToken) && !isNullOrUndefined(idToken['custom:Behavior']) && idToken['custom:Behavior'].length > 0)
            roles.forEach(role => {
                if (idToken['custom:Behavior'].indexOf(role) != -1) {
                    roleFound = true;
                }
            });

        return roleFound;
    });

export const getLoginUserRoleFromStore = createSelector(selectAuthStore, (state) => {
    const idToken = state.decoded.idToken;
    let loginUserRole: string;
    if (idToken) {
        const userRoles = idToken['custom:UserGroup']
        loginUserRole = userRoles[0];
    }
    return loginUserRole;
});

export const getUserProfileCountFromStore = createSelector(selectAuthStore, (state) => {
    return state.decoded.azureB2CIDToken.authData.UserProfiles.length;
});


export const getIsB2cLoginFhlbcIdentity = createSelector(selectAuthStore, (state) => {
    const profileScopeIDs = state.decoded.azureB2CIDToken.authData.UserProfiles.map(profile => profile.ProfileScopeID);
    if (!isNullOrUndefined(profileScopeIDs)) {
        if (profileScopeIDs.includes("FHLBC-User")) {
            return true;
        }
        else return false;
    }
});