import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AppConfigService } from "@app-shared/services";
import { ImportMcDataUpdateFileResponse } from "./models/ImportMcDataUpdateFileResponse";
import { ImportMpfDirectFundingResponse } from "./models/ImportMpfDirectFundingResponse";
import { SubProductResponse } from "./models/SubProductResponse";
import { subProductPriceSourceResponse } from "./models/subProductPriceSourceResponse";
import { subProductRemittanceTypeResponse } from "./models/subProductRemittanceTypeResponse";
import { CreateManageSubproductApiRequest } from "./models/CreateManageSubproductApiRequest";
import { CreateManageSubproductApiResponse } from "./models/CreateManageSubproductApiResponse";
import { SpecialFeatureCodesResponse } from "./models/SpecialFeatureCodesResponse";
import { SpecialFeatureCodeResponse } from "./models/SpecialFeatureCodeResponse";
import { SpecialFeatureCodesAPIRequest } from "./models/SpecialFeatureCodesAPIRequest";
import { productResponse } from "./models/productResponse";
import { SubproductValueResponse } from "./models/SubproductValueResponse";
import { GetSubProductBySubProductKeyApiResponse } from "./models/GetSubProductBySubProductKeyApiResponse";
import { HttpUtilHelper } from "src/shared/helpers/http-util-helper";
import { CreateManageAusCodeApiRequest } from "./models/CreateManageAusCodeApiRequest";
import { CreateManageAusCodeApiResponse } from "./models/CreateManageAusCodeApiResponse";
import { UpdatSpecialFeatureCodeAPIRequest } from "./models/UpdatSpecialFeatureCodeAPIRequest";
import { GetSpecialFeatureCodesByIdApiRequest } from "./models/GetSpecialFeatureCodesByIdApiRequest";
import { GetSpecialFeatureCodesByIdApiResponse } from "./models/GetSpecialFeatureCodesByIdApiResponse";
import { GetAllProgramCodesApiResponse } from "./models/GetAllProgramCodesApiResponse";
import { GetAllSubProgramApiResponse } from "./models/GetAllSubProgramApiResponse";
import { GetAllSubProgramLoanTypeApiResponse } from "./models/GetAllSubProgramLoanTypeApiResponse";
import { UpdateAUSCodeAPIRequest } from "./models/UpdateAUSCodeApiRequest";
import { UpdateSubProductCodesAPIRequest } from "./models/UpdateSubProductCodesAPIRequest";
import { GenerateSubProductAuditLogReportRequest } from "./models/inner/GenerateSubProductAuditLogReportRequest";
import { GetPermanentBuydownPricesAllHLBApiResponse } from "./models/GetPermanentBuydownPricesAllHLBApiResponse";
import {InsertPermanentBuyDownPricesApiRequest} from "./models/InsertPermanentBuyDownPricesApiRequest";
import {InsertPermanentBuyDownPricesApiResponse} from "./models/InsertPermanentBuyDownPricesApiResponse";
import { GetPermanentBuydownPrice_HLBIDApiRequest } from "./models/inner/GetPermanentBuydownPrice_HLBIDApiRequest";
import { GetPermanentBuydownPriceHLBIDApiResponse } from "./models/inner/GetPermanentBuydownPriceHLBIDApiResponse";
import { GetPermanentBuydownPrice_PFIIDApiResponse } from "./models/inner/GetPermanentBuydownPrice_PFIIDApiResponse";
import { GetLatestBannerMessageResponse } from "./models/GetLatestBannerMessageResponse";
import { InsertNewBannerMessageRequest } from "./models/InsertNewBannerMessageRequest";
import { InsertNewBannerMessageResponse } from "./models/InsertNewBannerMessageResponse";
import { GetHighestAnnualCFIThresholdApiResponse } from "./models/GetHighestAnnualCFIThresholdApiResponse";
import { IsAnnualCFIThresholdExistsAPIRequest } from "./models/IsAnnualCFIThresholdExistsAPIRequest";
import { SaveAnnualCFIThresholdAPIRequest } from "./models/SaveAnnualCFIThresholdAPIRequest";
import { IsAnnualCFIThresholdExistsResponse } from "./models/IsAnnualCFIThresholdExistsResponse";


@Injectable()
export class AdministrationService {
    private API_PATH = `${AppConfigService.Settings.Mpf.ApiEndpoint}`;
    private BUILT_API_PATH = `${this.API_PATH}/api/administration`;
    private API_SERVICE_PATH = `${this.BUILT_API_PATH}/administration`;
    private websocketOptions(connectionId: string) { return { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'connectionid': `${connectionId}` }) } }
    private options: object = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    constructor(private http: HttpClient) { }
    
    ExecuteRWTLoanFundingUpdates(): Observable<ImportMpfDirectFundingResponse> {
        return this.http.get<ImportMpfDirectFundingResponse>(`${this.API_SERVICE_PATH}/${"executeRWTLoanFundingUpdates".toLowerCase()}`, this.options)
            .pipe(map(response => response));
    }
    ImportMembershipAssetSize(connectionId: string): Observable<any> {
        return this.http.put<any>(`${this.API_SERVICE_PATH}/${'ImportMembershipAssetSize'.toLowerCase()}`,null, this.websocketOptions(connectionId))
            .pipe(map(response => response));
    }

    ImportMcData(): Observable<ImportMcDataUpdateFileResponse> {
        return this.http.post<ImportMcDataUpdateFileResponse>(`${this.API_SERVICE_PATH}/${"importMcData".toLowerCase()}`, this.options)
            .pipe(map(response => response));
    }

    GetSubProductBySubProductKeyforUpdate(req):Observable<SubproductValueResponse>{
        return this.http.post<SubproductValueResponse>(`${this.API_PATH}/${"GetSubProductBySubProductKey".toLowerCase()}`,req,this.options)
            .pipe(map(response => response));
    }

    GetAllSubProducts(): Observable<SubProductResponse> {
        return this.http.get<SubProductResponse>(`${this.API_SERVICE_PATH}/${"getallsubproducts".toLowerCase()}`, this.options)
            .pipe(map(response => response));
    }
    Insert(req: SpecialFeatureCodesAPIRequest): Observable<SpecialFeatureCodeResponse> {
        return this.http.post<SpecialFeatureCodeResponse>(`${this.API_SERVICE_PATH}/${"insert".toLowerCase()}`,req, this.options)
            .pipe(map(response => response));
    }
    GetAllPriceSources() : Observable<subProductPriceSourceResponse>{
        return this.http.get<subProductPriceSourceResponse>(`${this.API_SERVICE_PATH}/${"GetAllPriceSources".toLowerCase()}`,this.options)
                .pipe(map(response => response))
    }

    SaveSubProduct(req: CreateManageSubproductApiRequest): Observable<CreateManageSubproductApiResponse> {
        
        return this.http.post<CreateManageSubproductApiResponse>(`${this.API_SERVICE_PATH}/${'savesubproduct'.toLowerCase()}`, req, this.options)
            .pipe(map(response => response));
    }

    GetAllProgramCodes()
    {
        return this.http.get<GetAllProgramCodesApiResponse>(`${this.API_SERVICE_PATH}/${"GetAllProgramCodes".toLowerCase()}`, this.options)
            .pipe(map(response => response))
    }
    GetAllSubProgram()
    {
        return this.http.get<GetAllSubProgramApiResponse>(`${this.API_SERVICE_PATH}/${"GetAllSubProgram".toLowerCase()}`, this.options)
            .pipe(map(response => response))
    }

    GetAllSubProgramLoanType()
    {
        return this.http.get<GetAllSubProgramLoanTypeApiResponse>(`${this.API_SERVICE_PATH}/${"GetAllSubProgramLoanType".toLowerCase()}`, this.options)
            .pipe(map(response => response))
    }

    UpdateGridChanges(req: UpdateSubProductCodesAPIRequest): Observable<productResponse> {
        return this.http.post<productResponse>(`${this.API_SERVICE_PATH}/${"UpdateGridChanges".toLowerCase()}`,req, this.options)
            .pipe(map(response => response));
    }
    GetAllRemittanceTypes(): Observable<subProductRemittanceTypeResponse> {
        return this.http.get<subProductRemittanceTypeResponse>(`${this.API_SERVICE_PATH}/${"GetAllRemittanceTypes".toLowerCase()}`, this.options)
            .pipe(map(response => response))
    }
    GetAllSpecialFeatureCodes():Observable<SpecialFeatureCodesResponse>{
        return this.http.get<SpecialFeatureCodesResponse>(`${this.API_SERVICE_PATH}/${"getallspecialfeaturecodes".toLowerCase()}`,this.options)
            .pipe(map(response => response));
    }

    Update(req: UpdatSpecialFeatureCodeAPIRequest): Observable<SpecialFeatureCodeResponse> {
        return this.http.post<SpecialFeatureCodeResponse>(`${this.API_SERVICE_PATH}/${"update".toLowerCase()}`,req, this.options)
            .pipe(map(response => response));
    }

    GetSpecialFeatureCodesByID(req: GetSpecialFeatureCodesByIdApiRequest): Observable<GetSpecialFeatureCodesByIdApiResponse> {
        return this.http.get<GetSpecialFeatureCodesByIdApiResponse>(`${this.API_SERVICE_PATH}/${"getSpecialFeatureCodesByID".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
            .pipe(map(response => response));
    }
    
    GetAllProducts() : Observable<productResponse>{
        return this.http.get<productResponse>(`${this.API_SERVICE_PATH}/${"GetAllProducts".toLowerCase()}`,this.options)
                .pipe(map(response => response))
    }
    GetSubProductBySubProductKey(SubProductKey: number): Observable<GetSubProductBySubProductKeyApiResponse> {
        {
            return this.http.get<GetSubProductBySubProductKeyApiResponse>(`${this.API_SERVICE_PATH}/${"GetSubProductBySubProductKey".toLowerCase()}?${HttpUtilHelper.ConvertQueryString({ SubProductKey: SubProductKey })}`, this.options)
                .pipe(map(response => response));
        }
    }
    InsertAUSCode(req: CreateManageAusCodeApiRequest): Observable<CreateManageAusCodeApiResponse> {
        return this.http.post<CreateManageAusCodeApiResponse>(`${this.API_SERVICE_PATH}/${"InsertAUSCode".toLowerCase()}`,req, this.options)
            .pipe(map(response => response));
    }
     
    UpdateAUSCode(req: UpdateAUSCodeAPIRequest): Observable<CreateManageAusCodeApiResponse> {
        return this.http.post<CreateManageAusCodeApiResponse>(`${this.API_SERVICE_PATH}/${"UpdateAUSCode".toLowerCase()}`,req, this.options)
            .pipe(map(response => response));
    }

    GenerateSubProductAuditLogReport(req : GenerateSubProductAuditLogReportRequest): Promise<any> {
        return this.http.get(`${this.API_SERVICE_PATH}/${"GenerateSubProductAuditLogReport".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`,
         {observe: 'response', responseType:'arraybuffer' }).toPromise()
      }

      GetPermanentBuydownPriceByHLBID(HLBID: number): Observable<GetPermanentBuydownPriceHLBIDApiResponse> {
        {
            return this.http.get<GetPermanentBuydownPriceHLBIDApiResponse>(`${this.API_SERVICE_PATH}/${"GetPermanentBuydownPriceByHLBID".toLowerCase()}?${HttpUtilHelper.ConvertQueryString({ HLBID: HLBID })}`, this.options)
                .pipe(map(response => response));
        }
    }

    GetPermanentBuydownPriceByPFIID(PFIID: number): Observable<GetPermanentBuydownPrice_PFIIDApiResponse> {
        {
            return this.http.get<GetPermanentBuydownPrice_PFIIDApiResponse>(`${this.API_SERVICE_PATH}/${"GetPermanentBuydownPriceByPFIID".toLowerCase()}?${HttpUtilHelper.ConvertQueryString({ PFIID: PFIID })}`, this.options)
                .pipe(map(response => response));
        }
    }

      GetPermanentBuydownPricesAllHLB()
    {
        return this.http.get<GetPermanentBuydownPricesAllHLBApiResponse>(`${this.API_SERVICE_PATH}/${"GetPermanentBuydownPricesAllHLB".toLowerCase()}`, this.options)
            .pipe(map(response => response))
    }
    InsertPermanentBuydownPrices(req: InsertPermanentBuyDownPricesApiRequest): Observable<InsertPermanentBuyDownPricesApiResponse> {
        return this.http.post<InsertPermanentBuyDownPricesApiResponse>(`${this.API_SERVICE_PATH}/${"InsertPermanentBuydownPrices".toLowerCase()}`,req, this.options)
            .pipe(map(response => response));
    }

    GetLatestBannerMessage()
    {
        return this.http.get<GetLatestBannerMessageResponse>(`${this.API_SERVICE_PATH}/${"GetLatestBannerMessage".toLowerCase()}`, this.options)
            .pipe(map(response => response))
    }
    InsertNewBannerMessage(req: InsertNewBannerMessageRequest): Observable<InsertNewBannerMessageResponse> {
        return this.http.post<InsertNewBannerMessageResponse>(`${this.API_SERVICE_PATH}/${"InsertNewBannerMessage".toLowerCase()}`,req, this.options)
            .pipe(map(response => response));
    }
    GetHighestAnnualCFIThreshold():Observable<GetHighestAnnualCFIThresholdApiResponse>
    {
        return this.http.get<GetHighestAnnualCFIThresholdApiResponse>(`${this.API_SERVICE_PATH}/${"GetHighestAnnualCFIThreshold".toLowerCase()}`, this.options)
        .pipe(map(response => response));
    }
    
    IsAnnualCFIThresholdExists(req: IsAnnualCFIThresholdExistsAPIRequest): Observable<IsAnnualCFIThresholdExistsResponse> {
        return this.http.get<IsAnnualCFIThresholdExistsResponse>(`${this.API_SERVICE_PATH}/${"IsAnnualCFIThresholdExists".toLowerCase()}?${HttpUtilHelper.ConvertQueryString(req)}`, this.options)
            .pipe(map(response => response));
    }
    SaveAnnualCFIThreshold(req: SaveAnnualCFIThresholdAPIRequest): Observable<boolean> {
        return this.http.post<boolean>(`${this.API_SERVICE_PATH}/${"SaveAnnualCFIThreshold".toLowerCase()}`,req, this.options)
            .pipe(map(response => response));
    }
}
