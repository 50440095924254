import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { fhlbConstants } from '@app-shared/constants/fhlb-constants';
import { AppConfigService, CustomToastService } from '@app-shared/services';
import { EmpfRoutingHelper } from '@empf-shared/navigation/empf.navigation';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AppState } from 'src/store/reducers';
import { getRolesFromStore } from 'src/store/selectors';
import { isNullOrUndefined } from 'is-what';
import { NavItemHelper } from './helper/NavItemHelper';
import { INavItem } from './models/INavItem';


@Component({
  selector: 'sites-menu-container',
  templateUrl: './sites-menu.container.component.html',
  styleUrls: ['./sites-menu.container.component.sass']

})
export class SitesMenuContainerComponent implements OnInit {
  @Input() isOrigination: boolean;
  userRoles: string[] = []
  transactions: string = "transactions";
  upload: string = "upload";
  onNavigationEndSub: Subscription;
  constructor(private store: Store<AppState>,private activatedRoute: ActivatedRoute, private router: Router, private toastr: CustomToastService) {

  }
  navLinks: INavItem[]

  ngOnInit(): void {
    this.store.select(getRolesFromStore).pipe(take(1), map(x => this.userRoles = x)).subscribe()

    let allLinks = [
      this.getHomeLinks(this.isOrigination),
      this.getPricingLinks(),
      this.getTreasuryLinks(),
      this.getTransactionsLinks(),
      this.getExhibitsLinks(),
      this.getReportsLinks(),
      this.getResourcesLinks(),
      this.getUploadLinks()
    ]
    this.onNavigationEndSub = this.router.events.subscribe((event) => {
      
      if(event instanceof NavigationEnd){
        let navEndEvent = event as NavigationEnd
          //For page refresh logic to apply blue bar on refresh/reload
          let routeName = navEndEvent.url.split('/')[2];
          //For below 2 menus, route name & menu name are differents. So, using menu name for below 2 routes.
          if (routeName == 'transaction') {
            routeName = this.transactions;
          } else if (routeName == "documents") {
            routeName = this.upload;
          }
          if (routeName) {
            this.applySelection(routeName.charAt(0).toUpperCase() + routeName.slice(1));
          }
      }
    });
    this.navLinks = this.filterPermission(allLinks)
    //For page refresh logic to apply blue bar on refresh/reload
    setTimeout(() => {
      let routeName = this.router.url.split('/')[2];
      //For below 2 menus, route name & menu name are differents. So, using menu name for below 2 routes.
      if (routeName == 'transaction') {
        routeName = this.transactions;
      } else if (routeName == "documents") {
        routeName = this.upload;
      }
      if (routeName) {
        this.applySelection(routeName.charAt(0).toUpperCase() + routeName.slice(1));
      }
    });
  }

  collapseMenu(selectedMenu){
      let menu = document.getElementById(selectedMenu + "_Id");
      if(!isNullOrUndefined(menu)){
        menu?.classList?.add('hideBlock');
        setTimeout(() => {
         menu?.classList?.remove('hideBlock');
       }, 500);
      };
  }

  applySelection(selectedMenu) {
    this.toastr.remove();
    const activeLinks = <HTMLCollectionOf<HTMLElement>>document.getElementsByClassName('active');
    for (let i = 0; i < activeLinks.length; i++) {
      activeLinks[i]?.classList?.remove('active');
    }
    document.getElementById(selectedMenu)?.classList?.add('active');
  }

  filterPermission(navItem: INavItem[]): INavItem[] {
    for (let i = 0; i < navItem.length; i++) {
      if (!NavItemHelper.isLinkAllowed(this.userRoles, navItem[i])) {
        navItem[i] = null
      }

      if (!isNullOrUndefined(navItem[i]) && !isNullOrUndefined(navItem[i].children) && navItem[i].children.length > 0)
        navItem[i].children = this.filterPermission(navItem[i].children)
    }

    return navItem.filter(x => !isNullOrUndefined(x))
  }

  getHomeLinks(isOrigination: boolean): INavItem {
    let home: INavItem = {
      name: "Home",
      route: EmpfRoutingHelper.HomeLandingPath(),
      visibility: [fhlbConstants.EmpfPfiMpf, fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfScuMpf, fhlbConstants.EmpfFhlbankuserMpf]
    }

    if (isOrigination)
      home = {
        name: "Home",
        route: '/origination/home',
        visibility: [
          fhlbConstants.EmpfPfiMpf, fhlbConstants.EmpfHlbMpf,
          AppConfigService.Settings.Empf.Fhlbc_Mpf_Los_Security_Administrator,
          AppConfigService.Settings.Empf.Fhlbc_Mpf_Los_Security_Inquiry,
          AppConfigService.Settings.Empf.Fhlbc_Mpf_Los_Security_Inquiry_NOPII,
          AppConfigService.Settings.Empf.Fhlbc_Mpf_Los_Security_Superuser
        ]
      }


    return home;
  }

  getPricingLinks(): INavItem {
    let navitem: INavItem = {
      name: "Pricing",
      visibility: [fhlbConstants.EmpfPfiMpf, fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfScuMpf, fhlbConstants.EmpfFhlbankuserMpf,
      AppConfigService.Settings.Empf.Fhlbc_Mpf_Los_Security_Administrator,
      AppConfigService.Settings.Empf.Fhlbc_Mpf_Los_Security_Inquiry,
      AppConfigService.Settings.Empf.Fhlbc_Mpf_Los_Security_Inquiry_NOPII,
      AppConfigService.Settings.Empf.Fhlbc_Mpf_Los_Security_Superuser],
      children: [
        {
          name: "Current Pricing",
          route: EmpfRoutingHelper.CurrentPricingLandingPath(),
          visibility: [fhlbConstants.EmpfPfiMpf, fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfScuMpf, fhlbConstants.EmpfFhlbankuserMpf]
        },
        {
          name: "Archived Pricing",
          route: EmpfRoutingHelper.ArchivedPricingLandingPath(),
          visibility: [fhlbConstants.EmpfPfiMpf, fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfScuMpf, fhlbConstants.EmpfFhlbankuserMpf]
        },
        {
          name: "Current SRP Pricing",
          route: EmpfRoutingHelper.CurrentSRPPricingLandingPath(),
          visibility: [fhlbConstants.EmpfPfiMpf, fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfScuMpf, fhlbConstants.EmpfFhlbankuserMpf]
        },
        // {
        //   name: "Xtra MBS LLPA Grids",
        //   route: EmpfRoutingHelper.XtraMBSLLPAGridsLandingPath(),
        //   visibility: [fhlbConstants.EmpfPfiMpf, fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfScuMpf, fhlbConstants.EmpfFhlbankuserMpf]
        // },
        {
          name: "Pricing Adjustment Factors",
          route: EmpfRoutingHelper.PricingAdjustmentFactorLandingPath(),
          visibility: [fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfScuMpf]
        },
        {
          name: "PFI Level Adjustments",
          route: EmpfRoutingHelper.PFILevelAdjustmentsLandingPath(),
          visibility: [fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfScuMpf]
        },
        {
          name: "MPF Bank Pricing Schedules",
          route: EmpfRoutingHelper.MpfbankPricingSchdulesLandingPath(),
          visibility: [fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfScuMpf]
        }
      ]
    }

    if (AppConfigService.Settings.Empf.DisplayLLPAGridLink) {      
      navitem.children.push({
        name: "Xtra MBS LLPA Grids",
        route: EmpfRoutingHelper.XtraMBSLLPAGridsLandingPath(),
        visibility: [fhlbConstants.EmpfPfiMpf, fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfScuMpf, fhlbConstants.EmpfFhlbankuserMpf]
      });
    }
    return navitem;
  }

  getTreasuryLinks(): INavItem {
    let navitem: INavItem = {
      name: "Treasury",
      visibility: [fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfFhlbankuserMpf],
      children: [
        {
          name: "Treasury Summary",
          route: EmpfRoutingHelper.TreasurySummaryLandingPath(),
          visibility: [fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfFhlbankuserMpf]
        },
        {
          name: "Delivery Commitment Detail",
          route: EmpfRoutingHelper.TreasuryDeliverycommitmentLandingPath(),
          visibility: [fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfFhlbankuserMpf]
        },
        {
          name: "Loan Funding Detail",
          visibility: [fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfFhlbankuserMpf],
          children: [
            {
              name: "View",
              route: EmpfRoutingHelper.TreasuryLFViewLandingPath(),
              visibility: [fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfFhlbankuserMpf]
            },
            {
              name: "Download",
              route: EmpfRoutingHelper.TreasuryLFDownloadLandingPath(),
              visibility: [fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfFhlbankuserMpf]
            }
          ]
        },
      ]
    }

    return navitem;
  }

  getTransactionsLinks(): INavItem {
    return {
      name: "Transactions",
      visibility: [fhlbConstants.EmpfPfiMpf],
      children: [
        {
          name: "Summary",
          route: EmpfRoutingHelper.TransactionsSummaryLandingPath(),
          visibility: [fhlbConstants.EmpfPfiMpf]
        },
        {
          name: "Loan Presentment",
          route: EmpfRoutingHelper.LoanPresentmentLandingPath(),
          visibility: [fhlbConstants.EmpfPfiMpf]
        },
        {
          name: "Delivery Commitment",
          route: EmpfRoutingHelper.DeliveryCommitmentLandingPath(),
          visibility: [fhlbConstants.EmpfPfiMpf]
        },
        {
          name: "Loan Funding",
          route: EmpfRoutingHelper.LoanFundingLandingPath(),
          visibility: [fhlbConstants.EmpfPfiMpf]
        },
        {
          name: "Submit Batch",
          route: EmpfRoutingHelper.SubmitBatchLandingPath(),
          visibility: [fhlbConstants.EmpfPfiMpf]
        },
        {
          name: "Credit Enhancement Estimator",
          visibility: [fhlbConstants.EmpfPfiMpf],
          children: [
            {
              name: "File Upload",
              route: EmpfRoutingHelper.CEEstimatorFileUploadLandingPath(),
              visibility: [fhlbConstants.EmpfPfiMpf]
            },
            {
              name: "Interactive Entry",
              route: EmpfRoutingHelper.CEEstimatorInteractiveEntryLandingPath(),
              visibility: [fhlbConstants.EmpfPfiMpf]
            },
          ]
        },
        {
          name: "Servicing Transfer",
          route: EmpfRoutingHelper.TransactionsServicingTransferLandingPath(),
          visibility: [fhlbConstants.EmpfPfiMpf]
        }
      ]
    }
  }

  getExhibitsLinks(): INavItem {
    return {
      name: "Exhibits",
      visibility: [fhlbConstants.EmpfPfiMpf],
      children: [
        {
          name: "Selling",
          route: EmpfRoutingHelper.FormExhibitsOriginationLandingPath(),
          visibility: [fhlbConstants.EmpfPfiMpf]
        },
        {
          name: "Document Custody",
          route: EmpfRoutingHelper.FormExhibitsDocumentCustodyLandingPath(),
          visibility: [fhlbConstants.EmpfPfiMpf]
        },
        {
          name: "Servicing",
          route: EmpfRoutingHelper.FormExhibitsServicingLandingPath(),
          visibility: [fhlbConstants.EmpfPfiMpf]
        },
        {
          name: "Annual Eligibility Certification (OG1)",
          route: EmpfRoutingHelper.OgForm1LandingPath(),
          visibility: [fhlbConstants.EmpfPfiMpf]
        },
        {
          name: "MPF Fidelity / E&O Worksheet OG2",
          route: EmpfRoutingHelper.Og2FormLandingPath(),
          visibility: [fhlbConstants.EmpfPfiMpf]
        },
        {
          name: "Annual Certification Forms",
          route: EmpfRoutingHelper.AecLandingPath(),
          visibility: [fhlbConstants.EmpfPfiMpf, fhlbConstants.EmpfFhlbankuserMpf]
        }
      ]
    }
  }

  getReportsLinks(): INavItem {
    return {
      name: "Reports",
      visibility: [fhlbConstants.EmpfPfiMpf, fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfScuMpf, fhlbConstants.EmpfFhlbankuserMpf,
      AppConfigService.Settings.Empf.Fhlbc_Mpf_Los_Security_Administrator,
      AppConfigService.Settings.Empf.Fhlbc_Mpf_Los_Security_Inquiry,
      AppConfigService.Settings.Empf.Fhlbc_Mpf_Los_Security_Inquiry_NOPII,
      AppConfigService.Settings.Empf.Fhlbc_Mpf_Los_Security_Superuser],
      children: [
        {
          name: "Master Commitment",
          route: EmpfRoutingHelper.ReportsMasterCommitmentLandingPath(),
          visibility: [fhlbConstants.EmpfPfiMpf]
        },
        {
          name: "Delivery Commitment",
          route: EmpfRoutingHelper.ReportsDeliveryCommitmentLandingPath(),
          visibility: [fhlbConstants.EmpfPfiMpf]
        },
        {
          name: "Batch",
          route: EmpfRoutingHelper.ReportsBatchLandingPath(),
          visibility: [fhlbConstants.EmpfPfiMpf]
        },
        {
          name: "Credit Enhancement Estimator",
          route: EmpfRoutingHelper.CreditEnhancementEstimatorReportLandingPath(),
          visibility: [fhlbConstants.EmpfPfiMpf]
        },
        {
          name: "Custody",
          route: EmpfRoutingHelper.CustodyReportsLandingPath(),
          visibility: [fhlbConstants.EmpfPfiMpf]
        },
        {
          name: "Servicing Transfer",
          route: EmpfRoutingHelper.ServicingTransferReportLandingPath(),
          visibility: [fhlbConstants.EmpfPfiMpf]
        },
        {
          name: "Other PFI Reports",
          route: EmpfRoutingHelper.OtherPfiReportLandingPath(),
          visibility: [fhlbConstants.EmpfPfiMpf]
        },
        this.getMPFbankReports(),
        this.getPFISpecificReportsReports(),
        this.getPFIAnnualCertificationReports()
      ]
    }
  }

  getMPFbankReports(): INavItem {
    return {
      name: "MPF Bank Reports",
      visibility: [fhlbConstants.EmpfScuMpf, fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfFhlbankuserMpf],
      children: [
        {
          name: "Master Commitments",
          route: EmpfRoutingHelper.MpfBankMasterCommitmentrReportsLandingPath(),
          visibility: [fhlbConstants.EmpfScuMpf, fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfFhlbankuserMpf]
        },
        {
          name: "Delivery Commitments",
          route: EmpfRoutingHelper.MpfBankDeliveryCommitmentrReportsLandingPath(),
          visibility: [fhlbConstants.EmpfScuMpf, fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfFhlbankuserMpf]
        },
        {
          name: "Loan Funding",
          route: EmpfRoutingHelper.ReportsMPFLoanFundingLandingPath(),
          visibility: [fhlbConstants.EmpfScuMpf, fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfFhlbankuserMpf]
        },
        {
          name: "Custody",
          route: EmpfRoutingHelper.MpfBankCustodyReportsLandingPath(),
          visibility: [fhlbConstants.EmpfScuMpf, fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfFhlbankuserMpf]
        },
        {
          name: "Accounting",
          route: EmpfRoutingHelper.ReportsMPFAccountingLandingPath(),
          visibility: [fhlbConstants.EmpfScuMpf, fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfFhlbankuserMpf]
        },
        {
          name: "Other MPF Bank Reports",
          route: EmpfRoutingHelper.ReportsOtherMPFBankReportsLandingPath(),
          visibility: [fhlbConstants.EmpfScuMpf, fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfFhlbankuserMpf]
        }
      ]
    }
  }

  getPFISpecificReportsReports(): INavItem {
    return {
      name: "PFI Specific Reports",
      visibility: [fhlbConstants.EmpfScuMpf, fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfFhlbankuserMpf],
      children: [
        {
          name: "Master Commitments",
          route: EmpfRoutingHelper.PfiMasterCommitmentReportsLandingPath(),
          visibility: [fhlbConstants.EmpfScuMpf, fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfFhlbankuserMpf]
        },
        {
          name: "Batch",
          route: EmpfRoutingHelper.PFISpecificBatchReportsLandingPath(),
          visibility: [fhlbConstants.EmpfHlbMpf]
        },
        {
          name: "Custody",
          route: EmpfRoutingHelper.PfiCustodyReportsLandingPath(),
          visibility: [fhlbConstants.EmpfScuMpf, fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfFhlbankuserMpf]
        },
        {
          name: "Other PFI Reports",
          route: EmpfRoutingHelper.OtherPfiSpecificReportsLandingPath(),
          visibility: [fhlbConstants.EmpfScuMpf, fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfFhlbankuserMpf]
        }
      ]
    }
  }

  getPFIAnnualCertificationReports(): INavItem {
    return {
      name: "PFI Annual Certification",
      visibility: [fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfFhlbankuserMpf],
      children: [
        {
          name: "Annual Certification Forms",
          route: EmpfRoutingHelper.AecReportLandingPath(),
          visibility: [fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfFhlbankuserMpf]
        },
        {
          name: "Annual Certification Activity Report",
          route: EmpfRoutingHelper.AecActivityReportLandingPath(),
          visibility: [fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfFhlbankuserMpf]
        }
      ]
    }
  }

  getResourcesLinks(): INavItem {
    return {
      name: "Resources",
      visibility: [fhlbConstants.EmpfPfiMpf, fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfScuMpf, fhlbConstants.EmpfFhlbankuserMpf,
      AppConfigService.Settings.Empf.Fhlbc_Mpf_Los_Security_Administrator,
      AppConfigService.Settings.Empf.Fhlbc_Mpf_Los_Security_Inquiry,
      AppConfigService.Settings.Empf.Fhlbc_Mpf_Los_Security_Inquiry_NOPII,
      AppConfigService.Settings.Empf.Fhlbc_Mpf_Los_Security_Superuser],
      children: [
        {
          name: "Publications",
          visibility: [fhlbConstants.EmpfPfiMpf, fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfScuMpf, fhlbConstants.EmpfFhlbankuserMpf],
          children: [
            {
              name: "MPF Announcements",
              url: "https://www.fhlbmpf.com/ANNOUNCEMENTS/Pages/MPF-Announcements.aspx",
              visibility: [fhlbConstants.EmpfPfiMpf, fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfScuMpf, fhlbConstants.EmpfFhlbankuserMpf]
            },
            {
              name: "MPF Press Releases",
              url: "https://www.fhlbmpf.com/NEWSCENTER/Pages/MPF-Press-Releases.aspx",
              visibility: [fhlbConstants.EmpfPfiMpf, fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfScuMpf, fhlbConstants.EmpfFhlbankuserMpf]
            },
            {
              name: "MPF Marketing Bulletin",
              url: "https://www.fhlbmpf.com/ANNOUNCEMENTS/Pages/MPF-Marketing-Bulletins.aspx",
              visibility: [fhlbConstants.EmpfPfiMpf, fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfScuMpf, fhlbConstants.EmpfFhlbankuserMpf]
            }
          ]
        },
        {
          name: "Help and Training",
          visibility: [fhlbConstants.EmpfPfiMpf, fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfScuMpf, fhlbConstants.EmpfFhlbankuserMpf],
          children: [
            {
              name: "eMPF Tutorials",
              route: EmpfRoutingHelper.EmpfTutorialLandingPath(),
              visibility: [fhlbConstants.EmpfPfiMpf, fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfScuMpf, fhlbConstants.EmpfFhlbankuserMpf]
            },
            {
              name: "FAQs",
              route: EmpfRoutingHelper.FaqsLandingPath(),
              visibility: [fhlbConstants.EmpfPfiMpf, fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfScuMpf, fhlbConstants.EmpfFhlbankuserMpf]
            },
            {
              name: "Glossary",
              route: EmpfRoutingHelper.GlossaryLandingPath(),
              visibility: [fhlbConstants.EmpfPfiMpf, fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfScuMpf, fhlbConstants.EmpfFhlbankuserMpf]
            }
          ]
        },
        {
          name: "MPF Guides",
          route: EmpfRoutingHelper.OriginationAndServicingGuidesLandingPath(),
          visibility: [fhlbConstants.EmpfPfiMpf, fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfScuMpf, fhlbConstants.EmpfFhlbankuserMpf]
        },
         {
          name: "Product Information",
          route: EmpfRoutingHelper.ResourcesMpfProductInformationLandingPath(),
          visibility: [fhlbConstants.EmpfPfiMpf, fhlbConstants.EmpfHlbMpf,fhlbConstants.EmpfScuMpf,fhlbConstants.EmpfFhlbankuserMpf]
        },
        {
          name: "Sales Tools",
          route: EmpfRoutingHelper.ResourcesSalesToolsLandingPath(),
          visibility: [fhlbConstants.EmpfHlbMpf, fhlbConstants.EmpfScuMpf, fhlbConstants.EmpfFhlbankuserMpf]
        },
        {
          name: "Property Demographics",
          route: EmpfRoutingHelper.PropertyDemographicsLandingPath(),
          visibility: [fhlbConstants.EmpfPfiMpf]
        }
      ]
    }
  }

  getUploadLinks(): INavItem {
    return {
      name: "Upload",
      route: EmpfRoutingHelper.DocumentUploadLandingPath(),
      visibility: [fhlbConstants.EmpfPfiMpf]
    }
  }

  linkHasChildren(navitem: INavItem) {
    return NavItemHelper.linkHasChildren(navitem)
  }

  isExternalLink(navitem: INavItem) {
    return NavItemHelper.isExternalLink(navitem);
  }

}


