import { IAccessTokenRequest } from '@app-shared/models/accessTokenRequest.model';
import { SiteName } from '@app-shared/models/routingdata';
import { Action } from '@ngrx/store';
import { DecodeAzureB2CAccessToken } from '../reducers/models/DecodeAzureB2CAccessToken';
import { DecodeAzureB2CIDToken } from '../reducers/models/DecodeAzureB2CIDToken';
import { UserProfile } from '../reducers/models/UserProfile';
import { LoginFlow } from '@app-shared/constants/login-flow';


export enum AuthActionTypes {
  LOGIN_SUCCESS = '[Auth] Login Success',
  SET_SITE = '[Auth] Set Site',
  SET_REFRESH_STATUS = '[Auth] Set Refresh Status',
  REFRESH_TOKENS = '[Auth] Refresh Token',
  REFRESH_TOKENS_SUCCESS = '[Auth] Refresh Token Success',
  REFRESH_TOKENS_FAIL = '[Auth] Refresh Token Fail',
  LOG_OUT = '[Auth] Log Out',
  LOG_OUT_ACKNOWLEDGE = '[Auth] Log Out Acknowledge',
  LOG_OUT_SUCCESS = '[Auth] Log Out Success',
  MODAL_EXTEND_SESSION = '[Auth] Modal Extend Session',
  MODAL_LOG_OUT = '[Auth] Modal Log Out',
  HLB_BANK_LOGIN ='[Auth] Hlb Log In',
  USER_PROFILE_SELECTED = '[Auth] User Profile Selected',
  USER_PROFILE_CLEARED = '[Auth] User Profile Cleared'
  }


export class LogInSuccessAction implements Action {
    readonly type = AuthActionTypes.LOGIN_SUCCESS;
    constructor(public payload: {
    decodedAzureB2CIDToken: DecodeAzureB2CIDToken,
    encodedAzureB2CIDtoken: string
  }) { }
}

export class UserProfileSelectedAction implements Action{
  readonly type = AuthActionTypes.USER_PROFILE_SELECTED;
  constructor(public payload : {
    selectedProfile: string,
    userProfile: UserProfile,
    navigateToHome: string,
    loginFlow: LoginFlow
  }) { }
}

export class UserProfileClearedAction implements Action {
  readonly type = AuthActionTypes.USER_PROFILE_CLEARED;
  constructor() { }
}

export class SetSiteAction implements Action {
  readonly type = AuthActionTypes.SET_SITE;
  constructor(public payload: SiteName) { }
}

export class SetRefreshStatusAction implements Action {
  readonly type = AuthActionTypes.SET_REFRESH_STATUS;
  constructor(public payload: boolean) { }
}

export class RefreshTokensAction implements Action {  
  readonly type = AuthActionTypes.REFRESH_TOKENS;
  constructor(public payload: {
  updateEmpfSessionTime: boolean,
  navigateToHome: string,
  loginFlow: LoginFlow
}) { }
}

export class RefreshTokensSuccessAction implements Action {
  readonly type = AuthActionTypes.REFRESH_TOKENS_SUCCESS;
  constructor(public payload: {
    encodedAzureB2CIDToken: string,
    encodedAzureB2CAccessToken: string,
    updateEmpfSessionTime: boolean,
    navigateToHome: string,
    loginFlow: LoginFlow
  }) { }
}

export class RefreshTokensFailAction implements Action {
  readonly type = AuthActionTypes.REFRESH_TOKENS_FAIL;
  constructor(public payload: {
    updateEmpfSessionTime: boolean
    }) { }
}
export class LogOutAction implements Action {
  readonly type = AuthActionTypes.LOG_OUT;
  constructor(public payload: {
    acknowledge: boolean
  }) { }
}

export class LogOutAcknownledgeAction implements Action {
  readonly type = AuthActionTypes.LOG_OUT_ACKNOWLEDGE;
  constructor() { }
}

export class LogOutSuccessAction implements Action {
  readonly type = AuthActionTypes.LOG_OUT_SUCCESS;
  constructor() { }
}

export class ModalExtendSessionAction implements Action {
  readonly type = AuthActionTypes.MODAL_EXTEND_SESSION;
  constructor() { }
}

export class ModalLogOutAction implements Action {
  readonly type = AuthActionTypes.MODAL_LOG_OUT;
  constructor() { }
}

export class HlbBankLoginAction implements Action {
  readonly type = AuthActionTypes.HLB_BANK_LOGIN;
  constructor(public payload: {
    mpfBankKey: string,
    mpfBankName : string
  }) { }
}

export type All =
  | LogInSuccessAction
  | RefreshTokensAction
  | LogOutAction
  | LogOutAcknownledgeAction
  | SetRefreshStatusAction
  | LogOutSuccessAction
  | RefreshTokensSuccessAction
  | RefreshTokensFailAction
  | SetSiteAction
  | ModalExtendSessionAction
  | ModalLogOutAction
  | HlbBankLoginAction
  | UserProfileSelectedAction
  | UserProfileClearedAction
    
