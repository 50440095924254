import { Component, OnInit } from "@angular/core";
import { fhlbConstants } from "@app-shared/constants/fhlb-constants";

@Component({
    selector: 'empf-container',
    templateUrl: './empf.container.component.html',
    styleUrls: ['./empf.sass']
})
export class EmpfContainerComponent implements OnInit {
  showBreadCrumb: boolean = false;
  showBreadcrumbComp: boolean = false;
  ngOnInit() {
  }

  public componentAdded(component: object) {
    this.showBreadcrumbComponent(component);
  }
  public componentBreadCrumb(component: object) {
    this.showBreadCrumb = false;
    if (component.constructor.name === fhlbConstants.HomesContainerComponentName ||
      component.constructor.name === fhlbConstants.entryContainerComponentName) {
      this.showBreadCrumb = false;
    }
    else {
      this.showBreadCrumb = true;
    }
  }
  showBreadcrumbComponent(component: object) {
    let name = component.constructor.name;
    if (name === fhlbConstants.ResourcesComponent ||
      name === fhlbConstants.PricingMainComponent ||
      name === fhlbConstants.TransactionComponent ||
      name === fhlbConstants.TreasuryMainComponent ||
      name === fhlbConstants.ExhibitsComponent ||
      name === fhlbConstants.DeliveryCommitmentComponent ||
      name == fhlbConstants.ReportsMainComponent ||
      name == fhlbConstants.DocumentsContainerComponent) {
      
      this.showBreadcrumbComp = true;
    }
    else {
      this.showBreadcrumbComp = false;
    }


  }


}
